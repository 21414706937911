<template>
<div class="list">

<div class="listMain wrap">
<div class="listMainBox">
<div class="listMainLeft fl">
<div class="tit">{{slideHdName}}</div>
<div class="sort">
<div v-for="(item, index) in slideList" :key="index">
<a 
                v-if="item.url && item.url !== ''" 
                target="_blank" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.url">
                {{item.name}}
              </a>
<a 
                v-if="!item.url || item.url == ''" 
                :class="{ck : active  == item.entTChannelUuid}"
                :href="item.catalog === 1 ? `/listPage/${item.entTChannelUuid}` : item.catalog === 2 ? `/list/${item.entTChannelUuid}` : `/listPic/${item.entTChannelUuid}`">
                {{item.name}}
              </a>
</div>
</div>
</div>
<div class="listMainRight fr">

<div class="listWrap" v-for="(item,index) in listArr" :key="index + listKey">
<div class="listHd clearfloat">
<div class="fl listHdGropL">
<span>{{item.nav.name}}</span>
</div>
<div class="fr listHdGrop">
<a :href="`/list/${item.nav.entTChannelUuid}`" target="_blank">更多</a>
</div>
</div>
<div class="listContainer" style="min-height: 0px;margin-bottom: 0px;" >
<ul class="news_list">
<li v-for="(item1, index1) in item.list" :key="index1" class="clearfloat">
<div class="news_list_time fr">{{ dayjs(item1.publishDate).format('YYYY-MM-DD') }}</div>

<!-- <a target="_blank" :href="item1.url && item1.url !== '' ? item1.url : `/detail/${item1.entTChannelUuid}/${item1.entTArticleUuid}`">{{item1.title}}</a>-->

<a v-if="item1.url && item1.url !== ''" target="_blank" :href="item1.url">{{item1.title}}</a>
<a v-else-if="(!item.url || item.url == '') && item.entTChannelUuid =='00190001'" target="_blank" :href="`/detail/01370001/${item1.entTArticleUuid}`">{{item1.title}}</a>
<a v-else target="_blank" :href="`/detail/${item1.entTChannelUuid}/${item1.entTArticleUuid}`">{{item1.title}}</a>
</li>
</ul>
</div>
</div>

</div>
</div>
</div>
</div>
</template>
<script>
import { getChildChannel, articles,getChannelInfo } from '@/api/common'

export default {
  name: 'list',
  data(){
    return {
      listKey: '123456',
      slideHdTitle: '',
      viewSlideHdParent: true,
      slideHdParentName: '',
      slideHdParentUrl: '',
      slideHdName: '',
      hdTitle: '',
      hdName: '',
      pageCont: '', 
      active: 0,
      slideHd: {
        title: '新闻动态',
      },
      slideList:[],
      pageSize: 6,
      pageNum: 1,
      total: 0,
      listData: [],
      listId: '',
      listBar: [],
      listArr: [],
      listArrN: []
    }
  },
  mounted(){
    var _this = this
    _this.active = _this.$route.params.id
    _this.listId = _this.$route.params.id
    _this.getChildChannel()
    _this.getChannelInfo()
  },
  methods: {
    // 查询二级栏目
    getChildChannel() {
      var _this = this

      let data = {
        applicationUuid: _this.webId,
        parentUuid: _this.$route.params.id
      }
      getChildChannel(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          console.log('data :>> ', data);
          _this.slideList = data
          data.forEach((line, index)=>{
            // let params = {
            //   channelUuid:line.entTChannelUuid,
            //   pageNum: _this.pageNum,
            //   pageSize: _this.pageSize
            // }
            _this.listArrN.push({nav: line})
            let id = ''
            if(line.entTChannelUuid == '01370001'){
              id = '00190001'
            }
            else{
              id = line.entTChannelUuid
            }
            let params = {
              channelUuid:id,
              pageNum: _this.pageNum,
              pageSize: _this.pageSize
            }
            articles(params).then(res =>{
              const { code, data } = res
              if (code === 1 && data) {
                _this.$nextTick(() => {
                  _this.listData[index] = data.rows
                  _this.listArrN[index].list = data.rows
                })
              }
            })
          })
          setTimeout(() => {
            _this.listArr = [..._this.listArrN]
          }, 600)
        }
      })
    },

    getChannelInfo() {
      var _this = this
      let data = {
        channelUuid: _this.$route.params.id
      }
      getChannelInfo(data).then(res => {
        const { code, data } = res
        if (code === 1 && data) {
          _this.slideHdName = data.name
        }
      })
    },
  }
}
</script>